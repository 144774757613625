import { IActionContext } from '@msdyn365-commerce/core';
import { ICartActionResultWithCart } from '@msdyn365-commerce/global-state';
import { addDiscountCodeAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart, CartLine, Coupon, DiscountLine } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
/***
 * VSI Customization start
 * ICartActionSubStatusMfrm is used to send out custom message against exception codes
 * received against the addDiscountCodeAsync API for scenarios mentioned against
 * story number #6563 on ADO
 */
export declare type ICartActionStatusMfrm =
    | 'MAXQUANTITY'
    | 'NOTFOUND'
    | 'ALREADYADDED'
    | 'NOCONTENT'
    | 'EMPTYINPUT'
    | 'MISSINGDIMENSION'
    | 'QUANTITYLIMITS'
    | 'Microsoft_Dynamics_Commerce_Runtime_NotStartedCouponCode'
    | 'Microsoft_Dynamics_Commerce_Runtime_ExpiredCouponCode'
    | 'DoesNotApply'
    | 'Microsoft_Dynamics_Commerce_Runtime_ExclusiveCouponCannotBeAppliedWithOtherCoupons'
    | 'Microsoft_Dynamics_Commerce_Runtime_CouponExceedsDefinedUsageLimits'
    | 'Microsoft_Dynamics_Commerce_Runtime_ExceededUsageLimitCouponCode';

export interface ICartActionResultWithCartMfrm extends ICartActionResultWithCart {
    mfrmstatus?: ICartActionStatusMfrm;
}
/* VSI Customization end */

export default async function addPromoCode(
    cart: Readonly<Cart | undefined>,
    promoCode: string,
    actionContext: IActionContext
): Promise<ICartActionResultWithCartMfrm> {
    if (!cart) {
        return { cart: undefined, status: 'FAILED' };
    }

    /*if (hasPromoCode(cart, promoCode)) {
        return { cart: undefined, status: 'FAILED', substatus: 'ALREADYADDED' };
    }*/
    if (cart.CartLines && hasPromoCodeMfrm(cart.CartLines, promoCode)) {
        return <ICartActionResultWithCartMfrm>{ cart: undefined, status: 'FAILED', substatus: 'ALREADYADDED' };
    }

    return addDiscountCodeAsync({ callerContext: actionContext }, cart.Id, promoCode)
        .then(newCart => {
            // Retail server will still return success if a promo code is not valid,
            // but it won't actually add that promo code. So need to check if promo
            // code is actually in the new cart
            if (hasPromoCode(newCart, promoCode)) {
                /* VSI Customization start */
                // Check the DiscountLines in case of success to find if a promo code has acutually
                // got applied on a cartLine, in case it did not get applied return error
                // code DoesNotApply
                if (newCart.CartLines && !hasPromoCodeMfrm(newCart.CartLines, promoCode)) {
                    return <ICartActionResultWithCartMfrm>{ cart: newCart, mfrmstatus: 'DoesNotApply' };
                }
                /* VSI Customization end */
                // return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS' };
                /* VSI Customization start */
                return <ICartActionResultWithCartMfrm>{ cart: newCart, status: 'SUCCESS' };
                /* VSI Customization end */
            } else {
                // return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
                /* VSI Customization start */
                return <ICartActionResultWithCartMfrm>{ cart: undefined, status: 'FAILED' };
                /* VSI Customization end */
            }
        })
        .catch(error => {
            actionContext.telemetry.warning(error);

            // return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
            /* VSI Customization start */
            // Overriding default behavior of sending FAILED status; actual error code of exception
            // will be send back to the promocode.component.tsx to show custom error message
            return <ICartActionResultWithCartMfrm>{ cart: undefined, status: error.message, substatus: error.name, mfrmstatus: error.name };
            /* VSI Customization end */
        });
}

function hasPromoCode(cart: Cart, code: string): boolean {
    const codes = cart.Coupons
        ? cart.Coupons.map((coupon: Coupon) => {
              return coupon.Code!.toLowerCase();
          })
        : [];
    return codes.indexOf(code.toLowerCase()) > -1;
}

/* VSI Customization start

   This function is to facilitate custom message scenario for MFRM
   normally addDiscountCodeAsync return cart with Coupon/Promo applied
   even qualifying product is not in the cart. This method will check all
   cartLines to see if cartLine itself has Coupon/Promo available in discountLine
   on DiscountCode property. Based upon results of this function Does not apply cases
   will be executed.
*/
function hasPromoCodeMfrm(cartLines: CartLine[], code: string): boolean {
    const codes: string[] = [];
    cartLines.map((cartLine: CartLine) => {
        const discountLines = cartLine.DiscountLines;
        discountLines &&
            discountLines.map((discountLine: DiscountLine) => {
                discountLine.DiscountCode && discountLine.DiscountCode.length > 0 && codes.push(discountLine.DiscountCode.toLowerCase());
            });
    });

    return codes.indexOf(code.toLowerCase()) > -1;
}
/* VSI Customization end */
