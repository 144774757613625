import * as React from 'react';

import { Button, getPayloadObject, IPayLoad, ITelemetryContent, TelemetryConstant } from '@msdyn365-commerce-modules/utilities';
import MsDyn365, { IComponent, IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import { ICartState, getCartState } from '@msdyn365-commerce/global-state';
import { addCartLinesAsync, updateCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart, CartLine, DiscountLine } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import uuid from 'uuid';
// VSI Customization - starts
import { IMFIBonusCouponsDetail, IMFIBonusItemsDetailsEntity, IMFIBonusItemsResponse, IMFIGetBonusCouponsDetailsResponse, IMFIGetBonusItemsParameters } from '../../../../actions/BonusItemDataServiceEntities.g';
import { UserDefinedPromocodeInput } from '../../../../actions/user-defined-promocode.action';
//import { INotification, MfrmNotificationInput } from '../../../../data-actions/mfrm-notification.action';
import { INotification } from '../../../../data-actions/mfrm-notification.action';
import { hasArray } from '../../../../Utilities/emtpyChecker';
import { _getMFIGetBonusItems } from '../../../../Utilities/get-bonus-items';
import addPromoCode from '../../../../Utilities/mfrm-add-promo-code';
import { callUtagLink } from '../../../../Utilities/analytics/utagCaller';
import { PromoCodeAppliedOnCartInput } from '../../../../data-actions/promocode-applied-in-progress.action';
import { _isABonusCoupon } from './cartlineitem.component';
import { MFIbonusitemsInput } from '../../../../data-actions/mfrm-mfi-bonusitem.action';
import { default as LoadingButton } from 'mfcl/Button';
import { CartDataTestIds } from '../../../../common/automation/data-testids';
import { addUnitOfMeasureInAddCartLine } from "../../../../Utilities/cart-utils";
// VSI Customization - ends

export interface IPromoCodeProps extends IComponentProps<{}> {
    cart: ICartState | undefined;
    promoCodeHeadingText: string;
    appliedPromoCodeHeadingText: string;
    removePromoAriaLabelFormat: string;
    promoPlaceholderText: string;
    promoCodeApplyButtonText: string;
    collapseTimeOut: number;
    removePromoText: string;
    invalidPromoCodeErrorText: string;
    failedToAddPromoCodeErrorText: string;
    duplicatePromoCodeErrorText: string;
    failedToRemovePromoCodeErrorText: string;
    // VSI Customization - {#52133}
    appliedPromoText: string;
    notAppliedPromoText: string;
    // VSI Customization - {#52133} End

    /** The telemetry content */
    telemetryContent?: ITelemetryContent;

    // VSI Customization - starts
    notifications?: INotification[] | undefined;
    mfrmExpiredPromoCodeErrorText: string;
    mfrmDoesNotApplyToCartPromoCodeErrorText: string;
    mfrmCannotBeCombinedPromoCodeErrorText: string;
    mfrmUsageLimitPromoCodeErrorText: string;
    // VSI Customization - End
    promoCodeApplyCallback?(): void;
    bonusItemsStateShare?: IMFIBonusItemsDetailsEntity[] | undefined;
    promoCodeAddTextHeading: string;
}

export interface IPromoCodeComponent extends IComponent<IPromoCodeProps> { }

const PromoCodeComponentActions = {};

interface IPromoCodeState {
    isCollapseOpen: boolean;
    promoCodeInputValue: string;
    error: string;
    canApply: boolean;
    // VSI Customization - starts
    isPromoEnable: boolean;
    showPromoAppliedMessage: boolean;
    promoCodeArray?: string[];
    promoLoading: boolean;
    // VSI Customization - ends
}

/**
 *
 * The PromoCode component renders the promocode section.
 * @extends {React.PureComponent<IRefineSubmenuProps>}
 */
class PromoCode extends React.Component<IPromoCodeProps, IPromoCodeState> {
    public readonly payLoad: IPayLoad;
    // VSI Customization - starts
    public bonusCouponsArray: IMFIGetBonusCouponsDetailsResponse[] = [];
    public isBonusCoupon: boolean = false;
    public promoCouponsArray: string[] = [];
    public appliedPromos: string[] = [];
    private focusInput: React.RefObject<HTMLInputElement>;
    private appliedPromoCodes: string[] = [];
    private allActualBonusCoupons: string[] = [];
    // VSI Customization - ends
    constructor(props: IPromoCodeProps, state: IPromoCodeState) {
        super(props);
        this.payLoad = getPayloadObject('click', this.props.telemetryContent!, TelemetryConstant.ApplyPromoCode);
        this.state = {
            isCollapseOpen: false,
            promoCodeInputValue: '',
            error: '',
            canApply: false,
            // VSI Customization - starts
            isPromoEnable: false,
            showPromoAppliedMessage: false,
            promoLoading: false
        };

        this.focusInput = React.createRef();
        // VSI Customization - ends
    }

    // VSI Customization - starts
    public async componentDidMount(): Promise<void> {
        const {
            context: {
                actionContext,
                request
            }
        } = this.props;
        if (request.query?.utm_promo) {
            const cartState = await getCartState(actionContext);
            if (cartState) {
                await this._applyPromotion(cartState, request.query?.utm_promo);
            }
        }
    }
    public componentDidUpdate(prevProps: IPromoCodeProps, prevState: IPromoCodeState): void {
        if (prevState.promoCodeInputValue !== this.state.promoCodeInputValue) {
            this.setState({
                error: ''
            });
        }
        this._getBonusCouponsArray();
        // this._isBonusItemProductExists();
    }
    // VSI Customization - ends

    public render(): JSX.Element {
        // VSI Customization - starts
        const { isPromoEnable } = this.state;
        if (MsDyn365.isBrowser) {
            // All promocodes ENTERED by customer
            const previousPromoCode = localStorage.getItem('promoCode');
            // All promocodes applied to cart currently, As each cartline's unique promocode is stored in localstorage when cartlines are being rendering so there'd be no need to check for applied promocodes again & again in this file,
            const allPromoCodes = localStorage.getItem('allPromoCodes');
            const allAppliedPromoCodes: string[] = allPromoCodes ? JSON.parse(allPromoCodes) : [];

            if (previousPromoCode) {
                this.appliedPromos = previousPromoCode && JSON.parse(previousPromoCode);
                // Now check if currently applied promocodes are also in above list, concat otherwise
                allAppliedPromoCodes.map(appliedPromo => {
                    if(this.appliedPromos.indexOf(appliedPromo) === -1) {
                        this.appliedPromos.push(appliedPromo);
                    }
                });
            } else if (!hasArray(previousPromoCode)) {
                this.appliedPromos = allAppliedPromoCodes;
            }
            // Update localstorage as well
            localStorage.setItem('promoCode', JSON.stringify(this.appliedPromos));
        }
        // VSI Customization - {#52133} - Changes for Accessibility Only
        return (
            <div className='msc-promo-code__wrap' data-testid={CartDataTestIds.AddPromoCode}>
                {this._renderAppliedPromoCode(this.props)}
                {isPromoEnable && (
                    <>
                        {/* <div className='msc-promo-code-heading'>{this.props.promoCodeHeadingText}</div> */}
                        {this._renderForm(
                            this.props.promoPlaceholderText,
                            this.props.promoCodeApplyButtonText,
                            this.props.cart,
                            this.props.promoCodeHeadingText
                        )}
                        <p className={this.state.error ? 'msc-alert-danger msc-promo-code-alert' : ''} role='alert' aria-live='assertive'>
                            {this.state.error}
                        </p>
                        {this.state.showPromoAppliedMessage && <p role='alert' aria-live='assertive' className='msc-promo-code-alert-success'>promo code applied</p>}
                    </>
                )}
                <a href='javascript:void(0)' role='link' className={isPromoEnable ? 'msc-promo-code-enabled' : ''} onClick={this._handleOnClick}>
                    {isPromoEnable ? 'Cancel' : this.props.promoCodeAddTextHeading}
                </a>
            </div>
            // VSI Customization - ends
        );
    }
    // VSI Customization - starts
    private _handleOnClick = () => {
        this.setState({ isPromoEnable: !this.state.isPromoEnable });
        if (!this.state.isPromoEnable) {
            MsDyn365.isBrowser &&
                setTimeout(() => {
                    this.focusInput.current?.focus();
                });
            this.setState({ promoCodeInputValue: '', error: '', canApply: false });
            // this.props.context.actionContext.update(new UserDefinedPromocodeInput(), {userDefinedPromos: this.appliedPromos});
        }
    };
    // VSI Customization - ends

    private readonly _onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const error = e.target.value === '' ? '' : this.state.error;
        this.setState({
            promoCodeInputValue: e.target.value,
            error,
            canApply: !!e.target.value
        });
    };
    // VSI Customization - {#52133} - temp disable cyclomatic-complexity
    // tslint:disable: cyclomatic-complexity
    private updateCartlineQuantity = async (appliedPromo: string, cartState: ICartState | undefined): Promise<CartLine[]> => {
        // Check if there is already bonusItem applied, then a combined payload will be passed to API in cart.tsx
        const getLocalStorage = localStorage.getItem('BonusItemProduct');
        const previousAppliedPromo: IMFIBonusItemsDetailsEntity[] | undefined = getLocalStorage && JSON.parse(getLocalStorage);


        let bonusItemDeets: IMFIBonusItemsDetailsEntity[] | undefined;

        if (previousAppliedPromo && previousAppliedPromo.length > 0) {
            bonusItemDeets = this.props.bonusItemsStateShare;
        } else {
            const bonusItemsResponse: IMFIBonusItemsResponse | null = await _getMFIGetBonusItems(this.props.context, { CartId: this.props.cart?.cart.Id, Coupons: [appliedPromo.toUpperCase()] }, this.props.bonusItemsStateShare);
            bonusItemDeets = bonusItemsResponse?.BonusItemsDetails;

        }
        this.props.context.actionContext.update(new MFIbonusitemsInput(), bonusItemDeets);
        // this.props.context.actionContext.update(new MFIbonusitemsInput(), { bonusItems: bonusItemsResponse?.BonusItemsDetails, message: bonusItemsResponse?.Message });

        let requiresUpdateCartLines = false;
        const cartLinesToBeAddedLater: CartLine[] = [];
        if (bonusItemDeets) {
            for (const bid of bonusItemDeets) {
                const items = bid.Items;
                if (items) {
                    for (const it of items) {
                        const bItems = it.BonusItems;
                        if (bItems) {
                            for (const bi of bItems) {
                                let qualifyingItemQuantity = -1;
                                if (hasArray(cartState?.cart.CartLines)) {
                                    for (const cl of cartState!.cart.CartLines!) {
                                        if (cl.ItemId === it.QualifyingItemId && cl.ProductId?.toString() === it.QualifyingProductId?.toString()) {
                                            qualifyingItemQuantity = cl.Quantity ? cl.Quantity : 0;
                                        }
                                    }
                                }
                                if (qualifyingItemQuantity > 0) {
                                    if (hasArray(cartState?.cart.CartLines)) {
                                        for (const cl of cartState!.cart.CartLines!) {
                                            if (cl.ItemId === bi.BonusItemId && cl.ProductId?.toString() === bi.BonusProductId?.toString()) {
                                                if (cl.Quantity !== qualifyingItemQuantity) {
                                                    if (cl.Quantity && (cl.Quantity > qualifyingItemQuantity)) {
                                                        const laterToBeAddedCL = {
                                                            ProductId: cl.ProductId,
                                                            // ProductTypeValue: 5,
                                                            EntryMethodTypeValue: 5,
                                                            ItemId: cl.ItemId,
                                                            Quantity: cl.Quantity - qualifyingItemQuantity
                                                        };
                                                        cartLinesToBeAddedLater.push(laterToBeAddedCL);
                                                    }
                                                    cl.Quantity = qualifyingItemQuantity;
                                                    requiresUpdateCartLines = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (requiresUpdateCartLines) {
            await updateCartLinesAsync({ callerContext: this.props.context.actionContext }, cartState?.cart.Id ? cartState.cart.Id : '', cartState?.cart.CartLines);
            await cartState?.refreshCart({});
        }
        return cartLinesToBeAddedLater;
    };

    /* private getBonusItemsInfo = async (coupon: string): Promise<IMFIBonusItemsResponse | null> => {
        const requestBodyMFIBonus: IMFIGetBonusItemsParameters = {
            CartId: this.props.cart?.cart.Id,
            Coupons: [coupon.toUpperCase()]
        };
        const bonusItemsProducts = await _getMFIGetBonusItems(this.props.context, requestBodyMFIBonus);
        return bonusItemsProducts;
    }; */

    private checkQualifying = (coupon: string, bonusItemCouponResponse: IMFIBonusItemsResponse | null) => {
        if (bonusItemCouponResponse === undefined || bonusItemCouponResponse === null) {
            return false;
        }
        const bonusItemArray: IMFIBonusItemsResponse[] = [bonusItemCouponResponse];
        const cls = this.props.cart?.cart.CartLines;
        if (hasArray(cls)) {
            // get bonus item array from localstorage
            for (const bonusItemsArray of bonusItemArray) {
                // bonusItemArray.map((bonusItemsArray) => {
                // bonus product array against applied promocode
                if (bonusItemArray && bonusItemsArray.BonusItemsDetails) {
                    for (const bonusItemDetail of bonusItemsArray.BonusItemsDetails) {
                        // bonusItemsArray.BonusItemsDetails?.map((bonusItemDetail) => {
                        // bonus item details array with items of parent product
                        if (bonusItemDetail && bonusItemDetail.Items && (bonusItemDetail.Coupon?.toUpperCase() === coupon.toUpperCase())) {
                            for (const item of bonusItemDetail.Items) {
                                // bonusItemDetail.Items?.map((item) => {
                                // check if cartlineId is equal to parent or qualifying Id
                                for (const cartlineToUpdate of cls!) {
                                    if (item.QualifyingItemId === cartlineToUpdate.ItemId && item.QualifyingProductId?.toString() === cartlineToUpdate.ProductId?.toString()) {
                                        return true;
                                    }
                                }
                                // });
                            }
                        }
                    }
                }
            }
        }
        return false;
    };

    /* private promoCodeExistsInLocalStorage = (promoCode: string) => {
        const getLocalStorage = localStorage.getItem('BonusItemProduct');
        let bonusItemArray: IMFIBonusItemsResponse[] = [];
        const cls = this.props.cart?.cart.CartLines;
        if (getLocalStorage && hasArray(cls)) {
            const localStorageBonusInfo = getLocalStorage && JSON.parse(getLocalStorage);
            // get bonus item array from localstorage
            if (!hasArray(localStorageBonusInfo)) {
                if (localStorageBonusInfo && hasArray(localStorageBonusInfo.BonusItemsDetails)) {
                    bonusItemArray = [localStorageBonusInfo];
                } else {
                    return;
                }
            } else {
                bonusItemArray = localStorageBonusInfo;
            }
            for (const bonusItemsArray of bonusItemArray) {
                // bonusItemArray.map((bonusItemsArray) => {
                // bonus product array against applied promocode
                if (bonusItemArray && bonusItemsArray.BonusItemsDetails) {
                    for (const bonusItemDetail of bonusItemsArray.BonusItemsDetails) {
                        // bonusItemsArray.BonusItemsDetails?.map((bonusItemDetail) => {
                        // bonus item details array with items of parent product
                        if (bonusItemDetail && bonusItemDetail.Items && (bonusItemDetail.Coupon?.toUpperCase() === promoCode.toUpperCase())) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }; */

    // VSI Customization - {#52133} - temp disable max-func-body-length
    // tslint:disable: max-func-body-length
    private readonly _applyPromotion = async (cartState: ICartState | undefined, utm_promo?: string) => {
        if (!cartState || !cartState.cart) {
            return;
        }
        this.setState({ promoLoading: true });
        let appliedPromo = utm_promo ? utm_promo : this.state.promoCodeInputValue;
        if(appliedPromo) {
            appliedPromo = appliedPromo.trim();
        }
        if (this.isOneOfDisabledPromos(appliedPromo)) {
            if (!this.appliedPromos) {
                this.appliedPromos = [];
            }
            if (this.appliedPromos.indexOf(appliedPromo.toUpperCase()) === -1) {
                const previousPromoCode = localStorage.getItem('promoCode');
                if (previousPromoCode) {
                    this.promoCouponsArray = previousPromoCode && JSON.parse(previousPromoCode);
                    if (this.promoCouponsArray.indexOf(appliedPromo.toUpperCase()) === -1) {
                        this.promoCouponsArray.push(appliedPromo);
                    }
                    if (this.appliedPromos.indexOf(appliedPromo.toUpperCase()) === -1) {
                        this.appliedPromos.push(appliedPromo.toUpperCase());
                    }
                } else {
                    this.promoCouponsArray.push(appliedPromo.toUpperCase());
                    if (this.appliedPromos.indexOf(appliedPromo.toUpperCase()) === -1) {
                        this.appliedPromos.push(appliedPromo.toUpperCase());
                    }
                    // this.props.context.actionContext.update(new UserDefinedPromocodeInput(), {userDefinedPromos: this.promoCouponsArray});
                }
                localStorage.setItem('promoCode', JSON.stringify(this.promoCouponsArray));
                localStorage.setItem('allPromoCodes', JSON.stringify(this.promoCouponsArray));
                this.setState({
                    promoCodeInputValue: '',
                    error: '',
                    canApply: false,
                    isPromoEnable: false,
                    showPromoAppliedMessage: false
                });
            } else {
                this.setState({ error: this.props.duplicatePromoCodeErrorText });
            }
            this.setState({ promoLoading: false });
            return;
        }
        // VSI Customization - starts
        const promoData: INotification = {
            id: uuid.v4(),
            type: 'promocode',
            value: '',
            status: ''
        };
        let notificationItems: INotification[] = [];
        if (this.props.notifications && this.props.notifications.length) {
            notificationItems = [...this.props.notifications];
        }
        // VSI Customization - starts
        // check promo code applied array if existing item is there or not.

        const checkIsBonusCoupons = this._isBonusCoupons(appliedPromo);
        const mfrmCartState: Readonly<Cart | undefined> = this.props.cart?.cart;
        // const bonusLocalStorage = this.promoCodeExistsInLocalStorage(appliedPromo);
        const bonusLocalStorage = _isABonusCoupon(appliedPromo);
        let cartLinesToBeAddedLater: CartLine[] = [];

        let bonusItemsInfo: IMFIBonusItemsResponse | null;
        if (bonusLocalStorage) {
            // bonusItemsInfo = await this.getBonusItemsInfo(appliedPromo);
            cartLinesToBeAddedLater = await this.updateCartlineQuantity(appliedPromo, cartState);
        }

        // cartState.addPromoCode({ promoCode: appliedPromo })
        this.props.context.actionContext.update(new PromoCodeAppliedOnCartInput(), { isInProcess: true });
        addPromoCode(mfrmCartState, appliedPromo, this.props.context.actionContext)
            .then(async result => {
                this.setState({ promoLoading: false });
                if (result.status === 'SUCCESS') {
                    // Show success text
                    const previousPromoCode = localStorage.getItem('promoCode');
                    if (previousPromoCode) {
                        this.promoCouponsArray = previousPromoCode && JSON.parse(previousPromoCode);
                        if (this.promoCouponsArray.indexOf(appliedPromo.toUpperCase()) === -1) {
                            this.promoCouponsArray.push(appliedPromo);
                        }
                    } else {
                        this.promoCouponsArray.push(appliedPromo.toUpperCase());
                        if (this.appliedPromos.indexOf(appliedPromo.toUpperCase()) === -1) {
                            this.appliedPromos.push(appliedPromo.toUpperCase());
                        }
                        // this.props.context.actionContext.update(new UserDefinedPromocodeInput(), {userDefinedPromos: this.promoCouponsArray});
                    }
                    localStorage.setItem('promoCode', JSON.stringify(this.promoCouponsArray));
                    localStorage.setItem('allPromoCodes', JSON.stringify(this.promoCouponsArray));
                    promoData.value = appliedPromo;
                    this.setState({
                        promoCodeInputValue: '',
                        error: '',
                        canApply: false,
                        isPromoEnable: false,
                        showPromoAppliedMessage: false
                    });
                    promoData.status = result.status;
                    notificationItems.unshift(promoData);
                    //this.props.context.actionContext.update(new MfrmNotificationInput(), notificationItems);
                    // this._handleOnClick();
                } else if (result.substatus === 'ALREADYADDED') {
                    this.setState({ error: this.props.duplicatePromoCodeErrorText });
                    await callUtagLink({
                        event: 'add-remove-promos',
                        event_category: 'add/remove promos',
                        event_action: 'Add promo code click',
                        event_label: `${appliedPromo}: ${this.props.duplicatePromoCodeErrorText}`,
                        event_noninteraction: 'false'
                    });
                } else {
                    if (checkIsBonusCoupons) {
                        const upr = this.getUniqueAppliedPromos(this.props);
                        let bonusPrAlreadyAdded = false;
                        for (const apr of upr) {
                            if (apr.toUpperCase() === appliedPromo.toUpperCase()) {
                                bonusPrAlreadyAdded = true;
                            }
                        }
                        if (bonusPrAlreadyAdded) {
                            this.setState({ error: this.props.duplicatePromoCodeErrorText });
                            // @ts-ignore
                            await callUtagLink({
                                event: 'add-remove-promos',
                                event_category: 'add/remove promos',
                                event_action: 'Add promo code click',
                                event_label: `${appliedPromo}: ${this.props.duplicatePromoCodeErrorText}`,
                                event_noninteraction: 'false'
                            });
                        } else {
                            const previousPromoCode = localStorage.getItem('promoCode');
                            if (previousPromoCode) {
                                this.promoCouponsArray = previousPromoCode && JSON.parse(previousPromoCode);
                                if (this.promoCouponsArray.indexOf(appliedPromo.toUpperCase()) === -1) {
                                    checkIsBonusCoupons && this.promoCouponsArray.push(appliedPromo.toUpperCase());
                                }
                                localStorage.setItem('promoCode', JSON.stringify(this.promoCouponsArray));
                                localStorage.setItem('allPromoCodes', JSON.stringify(this.promoCouponsArray));
                            } else {
                                // cartState.refreshCart({});
                                this.promoCouponsArray.push(appliedPromo.toUpperCase());
                                localStorage.setItem('promoCode', JSON.stringify(this.promoCouponsArray));
                                localStorage.setItem('allPromoCodes', JSON.stringify(this.promoCouponsArray));
                            }
                            // this.props.context.actionContext.update(new UserDefinedPromocodeInput(), {userDefinedPromos: this.promoCouponsArray});
                            if (this.appliedPromos.indexOf(appliedPromo.toUpperCase()) === -1) {
                                this.appliedPromos.push(appliedPromo.toUpperCase());
                            }
                            promoData.value = appliedPromo;
                            if (bonusLocalStorage) {
                                promoData.status = this._qualifyingExists(appliedPromo) ? 'SUCCESS' : (this.isD365Applied(appliedPromo) ? 'SUCCESS' : 'NOT_APPLIED');
                            } else {
                                promoData.status = this.checkQualifying(appliedPromo, bonusItemsInfo) ? 'SUCCESS' : (this.isD365Applied(appliedPromo) ? 'SUCCESS' : 'NOT_APPLIED');
                            }
                            notificationItems.unshift(promoData);
                            //this.props.context.actionContext.update(new MfrmNotificationInput(), notificationItems);
                            checkIsBonusCoupons
                                ? this.setState({
                                    promoCodeInputValue: '',
                                    error: '',
                                    canApply: false,
                                    isPromoEnable: false,
                                    showPromoAppliedMessage: false
                                })
                                : this.setState({ error: this.props.invalidPromoCodeErrorText });
                        }
                    } else {
                        // commented next for #6563
                        // this.setState({ error: this.props.invalidPromoCodeErrorText });
                        if (this.appliedPromos.indexOf(appliedPromo.toUpperCase()) === -1) {
                            this.appliedPromos.push(appliedPromo.toUpperCase());
                        }
                        /* start #6563 */
                        const previousPromoCode = localStorage.getItem('promoCode');
                        if (previousPromoCode) {
                            this.promoCouponsArray = previousPromoCode && JSON.parse(previousPromoCode);
                            if (this.promoCouponsArray.indexOf(appliedPromo.toUpperCase()) === -1) {
                                this.promoCouponsArray.push(appliedPromo.toUpperCase());
                            }
                            localStorage.setItem('promoCode', JSON.stringify(this.promoCouponsArray));
                            localStorage.setItem('allPromoCodes', JSON.stringify(this.promoCouponsArray));
                        } else {
                            // cartState.refreshCart({});
                            this.promoCouponsArray.push(appliedPromo.toUpperCase());
                            localStorage.setItem('promoCode', JSON.stringify(this.promoCouponsArray));
                            localStorage.setItem('allPromoCodes', JSON.stringify(this.promoCouponsArray));
                        }
                        let exceptionCustomMessage = this.props.invalidPromoCodeErrorText;
                        switch (result.mfrmstatus) {
                            case 'Microsoft_Dynamics_Commerce_Runtime_NotStartedCouponCode':
                            case 'DoesNotApply':
                                exceptionCustomMessage = this.props.mfrmDoesNotApplyToCartPromoCodeErrorText; // `This promo doesn't apply to your cart.`;
                                break;

                            case 'Microsoft_Dynamics_Commerce_Runtime_ExpiredCouponCode':
                                exceptionCustomMessage = this.props.mfrmExpiredPromoCodeErrorText; // `We're sorry, this promo has expired.`;
                                break;

                            case 'Microsoft_Dynamics_Commerce_Runtime_ExclusiveCouponCannotBeAppliedWithOtherCoupons':
                                exceptionCustomMessage = this.props.mfrmCannotBeCombinedPromoCodeErrorText; // `This promo can't be combined with other promos in your cart.`;
                                break;

                            case 'Microsoft_Dynamics_Commerce_Runtime_CouponExceedsDefinedUsageLimits':
                            case 'Microsoft_Dynamics_Commerce_Runtime_ExceededUsageLimitCouponCode':
                                exceptionCustomMessage = this.props.mfrmUsageLimitPromoCodeErrorText; // `We're sorry, this promotion is no longer valid.`;
                                break;

                            default:
                                exceptionCustomMessage = this.props.invalidPromoCodeErrorText;
                        }
                        // @ts-ignore
                        // tslint:disable-next-line: object-literal-key-quotes
                        await callUtagLink({
                            event: 'add-remove-promos',
                            event_category: 'add/remove promos',
                            event_action: 'Add promo code click',
                            event_label: `${appliedPromo}: ${exceptionCustomMessage}`,
                            event_noninteraction: 'false'
                        });
                        this.setState({ error: exceptionCustomMessage });
                        /* end #6563 */
                    }
                }
                await cartState.refreshCart({});
                this.props.context.actionContext.update(new UserDefinedPromocodeInput(), { userDefinedPromos: this.appliedPromos });
                if (hasArray(cartLinesToBeAddedLater)) {
                    const { actionContext, request: { apiSettings: { channelId}}} = this.props.context;
                    cartLinesToBeAddedLater = await addUnitOfMeasureInAddCartLine(cartLinesToBeAddedLater, actionContext, channelId);
                    await addCartLinesAsync({ callerContext: this.props.context.actionContext }, cartState?.cart.Id ? cartState.cart.Id : '', cartLinesToBeAddedLater);
                    await cartState.refreshCart({});
                }
                this.props.context.actionContext.update(new PromoCodeAppliedOnCartInput(), { isInProcess: false });
                // VSI Customization - ends
            })
            .catch(error => {
                this.props.context.actionContext.update(new PromoCodeAppliedOnCartInput(), { isInProcess: false });
                this.setState({ error: this.props.failedToAddPromoCodeErrorText, promoLoading: false });
            });
        // VSI Customization - {#52133} - re-enable cyclomatic-complexity and max-func-body-length
        // tslint:enable: cyclomatic-complexity
        // tslint:enable: max-func-body-length
    };

    private readonly _renderForm = (
        promoPlaceholderText: string,
        promoCodeApplyButtonText: string,
        cartState: ICartState | undefined,
        promoCodeLabelText: string
    ) => {
        const _onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            void this._applyPromotion(cartState);
        };
        // VSI Customization - starts
        // const _applyPromotion = (event: React.MouseEvent<HTMLElement>) => {this._applyPromotion(cartState);};
        // const attributes = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);

        return (
            <>
                <form onSubmit={_onSubmit} className='msc-promo-code__form-container'>
                    <div className='msc-promo-code__group'>
                        <input
                            type='text'
                            className={`msc-promo-code__input-box msc-form-control field-control${this.state.error ? '--error' : ''} ${this.state.promoCodeInputValue ? 'field-control--value' : ''
                                }`}
                            aria-label={promoPlaceholderText}
                            onChange={this._onInputChange}
                            value={this.state.promoCodeInputValue}
                            ref={this.focusInput}
                        />
                        <label className='msc-promo-code__label field-label'>{promoCodeLabelText}</label>
                        {this.state.promoLoading ?
                            <LoadingButton
                                className='msc-promo-code__apply-btn btn'
                                title="loading-button"
                                color='primary'
                                loading size="sm"
                            /> :
                            <button className='msc-promo-code__apply-btn btn' disabled={!this.state.canApply} type='submit'>
                                {promoCodeApplyButtonText}
                            </button>
                        }
                        {/* <Button
                            title={promoCodeApplyButtonText}
                            className='msc-promo-code__apply-btn btn'
                            onClick={_applyPromotion}
                            disabled={!this.state.canApply}
                            {...attributes}
                        >
                            {promoCodeApplyButtonText}
                        </Button> */}
                    </div>
                </form>
            </>
        );
        // VSI Customization - ends
    };

    private _updateBonusItems = (bonusItemsParams: string[], cartState: ICartState | undefined) => {
        if (!cartState) {
            return;
        }
        if (cartState.cart.Id) {
            localStorage.removeItem('BonusItemProduct');
            const requestBodyMFIBonus: IMFIGetBonusItemsParameters = {
                CartId: cartState.cart.Id,
                Coupons: bonusItemsParams
            };
            const getBonusItemsProducts = _getMFIGetBonusItems(this.props.context, requestBodyMFIBonus, this.props.bonusItemsStateShare);
            getBonusItemsProducts.then(async (bonusItemsResponse) => {
                if (bonusItemsResponse !== undefined) {
                    localStorage.setItem('BonusItemProduct', JSON.stringify(bonusItemsResponse));
                }
            }).catch((e) => {
                console.log('Error', e);
            });
            return;
        }
    };

    private readonly _removePromotion = (cartState: ICartState | undefined, event: React.MouseEvent) => {
        if (!cartState) {
            return;
        }
        // VSI Customization - starts
        let notificationItems: INotification[] = [];
        if (this.props.notifications && this.props.notifications.length) {
            notificationItems = [...this.props.notifications];
        }
        const promoData: INotification = {
            id: uuid.v4(),
            type: 'promocode',
            value: '',
            status: ''
        };
        // VSI Customization - end
        const code = event.currentTarget.getAttribute('data-value') || '';
        if (this.isOneOfDisabledPromos(code)) {
            // check promo code applied array if existing item is there or not
            let currentPromoArray: string[] = [];
            const getLocalPromoValue = localStorage.getItem('promoCode');
            this.promoCouponsArray = getLocalPromoValue && JSON.parse(getLocalPromoValue);
            if (getLocalPromoValue) {
                currentPromoArray = this.promoCouponsArray.filter(items => items?.toUpperCase() !== code.toUpperCase());
                this.promoCouponsArray = currentPromoArray;
            }

            if (currentPromoArray.length === 0) {
                localStorage.removeItem('BonusItemProduct');
                localStorage.removeItem('promoCode');
                localStorage.removeItem('allPromoCodes');
                this.promoCouponsArray = [];
                this.setState({
                    promoCodeInputValue: '',
                    error: '',
                    canApply: false,
                    isPromoEnable: false,
                    showPromoAppliedMessage: false
                });
                void cartState.refreshCart({});
            } else if (currentPromoArray.length > 0) {
                localStorage.removeItem('promoCode');
                localStorage.removeItem('allPromoCodes');
                let BonusCouponCount = 0;
                const bonusItemsParams: string[] = [];
                currentPromoArray.map((item) => {
                    const checkIsBonusCoupons = this._isBonusCoupons(item);
                    if (checkIsBonusCoupons) {
                        bonusItemsParams.push(item);
                        BonusCouponCount++;
                    }
                });
                if (BonusCouponCount === 0) {
                    localStorage.removeItem('BonusItemProduct');
                } else {
                    this._updateBonusItems(bonusItemsParams, cartState);
                }
                localStorage.setItem('promoCode', JSON.stringify(this.promoCouponsArray));
                localStorage.setItem('allPromoCodes', JSON.stringify(this.promoCouponsArray));
                cartState.refreshCart({});
            } else {
                localStorage.setItem('promoCode', JSON.stringify(this.promoCouponsArray));
                localStorage.setItem('allPromoCodes', JSON.stringify(this.promoCouponsArray));
                this.props.context.actionContext.update(new UserDefinedPromocodeInput(), { userDefinedPromos: this.promoCouponsArray });
            }
            this.setState({ error: '' });
            return;
        }
        this.props.context.actionContext.update(new PromoCodeAppliedOnCartInput(), { isInProcess: true });
        cartState
            .removePromoCodes({
                promoCodes: [code]
            })
            .then(async result => {
                this.props.context.actionContext.update(new PromoCodeAppliedOnCartInput(), { isInProcess: false });
                if (result.status === 'SUCCESS') {
                    // check promo code applied array if existing item is there or not
                    let currentPromoArray: string[] = [];
                    const getLocalPromoValue = localStorage.getItem('promoCode');
                    this.promoCouponsArray = getLocalPromoValue && JSON.parse(getLocalPromoValue);
                    if (getLocalPromoValue) {
                        currentPromoArray = this.promoCouponsArray.filter(items => items?.toUpperCase() !== code.toUpperCase());
                        this.promoCouponsArray = currentPromoArray;
                    }

                    if (currentPromoArray.length === 0) {
                        localStorage.removeItem('BonusItemProduct');
                        localStorage.removeItem('promoCode');
                        localStorage.removeItem('allPromoCodes');
                        this.promoCouponsArray = [];
                        this.setState({
                            promoCodeInputValue: '',
                            error: '',
                            canApply: false,
                            isPromoEnable: false,
                            showPromoAppliedMessage: false
                        });
                        await cartState.refreshCart({});
                    } else if (currentPromoArray.length > 0) {
                        localStorage.removeItem('promoCode');
                        localStorage.removeItem('allPromoCodes');
                        let BonusCouponCount = 0;
                        const bonusItemsParams: string[] = [];
                        currentPromoArray.map((item) => {
                            const checkIsBonusCoupons = this._isBonusCoupons(item);
                            if (checkIsBonusCoupons) {
                                bonusItemsParams.push(item);
                                BonusCouponCount++;
                            }
                        });
                        if (BonusCouponCount === 0) {
                            localStorage.removeItem('BonusItemProduct');
                        } else {
                            this._updateBonusItems(bonusItemsParams, cartState);
                        }
                        localStorage.setItem('promoCode', JSON.stringify(this.promoCouponsArray));
                        localStorage.setItem('allPromoCodes', JSON.stringify(this.promoCouponsArray));
                        await cartState.refreshCart({});
                    } else {
                        localStorage.setItem('promoCode', JSON.stringify(this.promoCouponsArray));
                        localStorage.setItem('allPromoCodes', JSON.stringify(this.promoCouponsArray));
                        this.props.context.actionContext.update(new UserDefinedPromocodeInput(), { userDefinedPromos: this.promoCouponsArray });
                    }
                    this.setState({ error: '' });
                    // VSI Customization - starts
                    promoData.value = code;
                    promoData.status = 'error';
                    notificationItems.unshift(promoData);
                    //this.props.context.actionContext.update(new MfrmNotificationInput(), notificationItems);
                    // VSI Customization - ends
                }
            })
            .catch(() => {
                this.setState({ error: this.props.failedToRemovePromoCodeErrorText });
            });
    };

    private isD365Coupon = (coupon: string, props: IPromoCodeProps) => {
        const cart = props.cart?.cart;
        if (cart && hasArray(cart.Coupons)) {
            for (const cp of cart.Coupons!) {
                if (cp.Code?.toUpperCase() === coupon?.toUpperCase()) {
                    return true;
                }
            }
        }
        return false;
    };

    private getUniqueAppliedPromos = (props: IPromoCodeProps): string[] => {
        const uniqueAppliedPromos: string[] = [];
        if (hasArray(this.appliedPromos)) {
            for (const ap of this.appliedPromos) {
                const includesValue = uniqueAppliedPromos.some(element => {
                    return element.toUpperCase() === ap.toUpperCase();
                });
                if ((!includesValue) && (this._isBonusCoupons(ap) || this.isD365Coupon(ap, props) || this.isOneOfDisabledPromos(ap))) {
                    uniqueAppliedPromos.push(ap);
                }
            }
        }
        return uniqueAppliedPromos;
    };

    private _qualifyingExists = (coupon: string): boolean => {
        const getLocalStorage = localStorage.getItem('BonusItemProduct');
        let bonusItemArray: IMFIBonusItemsResponse[] = [];
        const cls = this.props.cart?.cart.CartLines;
        if (getLocalStorage && hasArray(cls)) {
            const localStorageBonusInfo = getLocalStorage && JSON.parse(getLocalStorage);
            // get bonus item array from localstorage
            if (!hasArray(localStorageBonusInfo)) {
                if (localStorageBonusInfo && hasArray(localStorageBonusInfo.BonusItemsDetails)) {
                    bonusItemArray = [localStorageBonusInfo];
                } else {
                    return false;
                }
            } else {
                bonusItemArray = localStorageBonusInfo;
            }
            // get bonus item array from localstorage
            for (const bonusItemsArray of bonusItemArray) {
                // bonusItemArray.map((bonusItemsArray) => {
                // bonus product array against applied promocode
                if (bonusItemArray && bonusItemsArray.BonusItemsDetails) {
                    for (const bonusItemDetail of bonusItemsArray.BonusItemsDetails) {
                        // bonusItemsArray.BonusItemsDetails?.map((bonusItemDetail) => {
                        // bonus item details array with items of parent product
                        if (bonusItemDetail && bonusItemDetail.Items && (bonusItemDetail.Coupon?.toUpperCase() === coupon.toUpperCase())) {
                            for (const item of bonusItemDetail.Items) {
                                // bonusItemDetail.Items?.map((item) => {
                                // check if cartlineId is equal to parent or qualifying Id
                                for (const cartlineToUpdate of cls!) {
                                    if (item.QualifyingItemId === cartlineToUpdate.ItemId && item.QualifyingProductId?.toString() === cartlineToUpdate.ProductId?.toString()) {
                                        return true;
                                    }
                                }
                                // });
                            }
                        }
                    }
                }
            }
        }
        return false;
    };

    private isD365Applied = (coupon: string) => {
        const cart = this.props.cart?.cart;
        const bonusCoupon = this.bonusCouponsArray && this.bonusCouponsArray[0] && this.bonusCouponsArray[0].BonusCoupons?.find(item => item.BonusCoupon?.toUpperCase() === coupon.toUpperCase());
        if (cart && hasArray(cart.CartLines)) {
            for (const cl of cart.CartLines!) {
                if (hasArray(cl.DiscountLines)) {
                    for (const dl of cl.DiscountLines!) {
                        if (dl.DiscountCode?.toUpperCase() === coupon.toUpperCase()) {
                            return true;
                        }
                        else if (bonusCoupon && bonusCoupon.ChildCoupons) {
                            for (const child of bonusCoupon.ChildCoupons){
                                if (dl.DiscountCode?.toUpperCase() === child.toUpperCase()) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    };

    private isOneOfDisabledPromos = (appliedPromo: string): boolean => {
        const disabledPromos = this.props.context.app.config.disabledPromosNames;
        if (disabledPromos) {
            const splittedDisabledPromos = disabledPromos.split(',');
            for (const disabledPromo of splittedDisabledPromos) {
                if (appliedPromo.trim().toUpperCase() === disabledPromo.trim().toUpperCase()) {
                    return true;
                }
            }
        }
        return false;
    };


    private readonly _renderAppliedPromoCode = (props: IPromoCodeProps) => {
        const promoAppliedList: string[] = [];
        if (!props.cart || !props.cart.cart || !props.cart.cart.Coupons || !(props.cart.cart.Coupons.length > 0)) {
            let hasOneOfDisabledPromos = false;
            if (this.appliedPromos && this.appliedPromos.length) {
                for (const apr of this.appliedPromos) {
                    if (this.isOneOfDisabledPromos(apr) && props.cart?.cart && props.cart.cart?.CartLines && props.cart?.cart?.CartLines?.length) {
                        hasOneOfDisabledPromos = true;
                    }
                }
            }
            if (!hasOneOfDisabledPromos) {
                if (MsDyn365.isBrowser) {
                    localStorage.setItem('promo_code_apply_list', JSON.stringify(promoAppliedList));
                }
                return;
            }
        }
        const _removePromotion = (event: React.MouseEvent<HTMLElement>) => { this._removePromotion(props.cart, event); };
        // 6563 VSI Customization - start
        // get applied promo codes based upon discountLines in cartLines
        this.promoCouponsArray = props.cart?.cart && props.cart?.cart.CartLines ? this._getAppliedPromoCodes(props.cart?.cart.CartLines) : [];
        // VSI Customization - end
        return (
            <>
                {/* VSI Customization - starts */}
                {/* <div className='msc-promo-code__discount'>
                    <div className='msc-promo-code__discount-heading'>{this.props.appliedPromoCodeHeadingText}</div>
                    <PriceComponent
                        data={{
                            price: {
                                CustomerContextualPrice: props.cart.cart.DiscountAmount

                            }
                        }}
                        context = {props.context}
                        id = {props.id}
                        typeName = {props.typeName}
                        className = {'msc-promo-code__discount-value'}
                    />
                </div> */}
                {/* VSI Customization - ends */}
                {// TODO Code will change this after promo message story completed
                    // props.cart.cart.Coupons.map((coupon: Coupon) => {
                    this.appliedPromos &&
                    this.getUniqueAppliedPromos(props).map((coupon: string) => {
                        //  this.promoCouponsArray && this.promoCouponsArray.map((coupon: string) => {
                        let notApplied = !this._qualifyingExists(coupon);
                        if (notApplied) {
                            notApplied = !this.isD365Applied(coupon);
                        }
                        // let applied = true;
                        // if (this.promoCouponsArray){
                        //     const includesValue = this.promoCouponsArray.some(element => {
                        //         return element.toUpperCase() === coupon.toUpperCase();
                        //     });
                        //     if(includesValue) {
                        //         applied = false;
                        //     }
                        // }

                        const className = notApplied ? 'msc-promo-code__line-value msc-text-error' : 'msc-promo-code__line-value';
                        if (!notApplied) {
                            // VSI Customization - {#52133} - change coupon text
                            const dataCoupon = `${coupon} ${props.appliedPromoText}`;
                            if (!promoAppliedList.includes(dataCoupon)) {
                                promoAppliedList.push(dataCoupon);
                            }
                        }
                        localStorage.setItem('promo_code_apply_list', JSON.stringify(promoAppliedList));
                        const disabledPromoMessage = this.props.context.app.config.disabledPromosNotAppliedText ? this.props.context.app.config.disabledPromosNotAppliedText.replace('{coupon}', coupon.toUpperCase()) : `${coupon} not applied. Call, chat online or visit a store to redeem offer`;
                        return (
                            <div key={coupon} className='msc-promo-code__line-container'>
                                <div className={className}>
                                    {/* VSI Customization - starts + {#52133} */}
                                    {/* {notApplied ? `${coupon.toUpperCase()} ${this.props.notAppliedPromoText}` : `${coupon.toUpperCase()} ${this.props.appliedPromoText}`} */}
                                    {this.isOneOfDisabledPromos(coupon.toUpperCase()) ? disabledPromoMessage : (notApplied ? `${coupon.toUpperCase()} ${this.props.notAppliedPromoText}` : `${coupon.toUpperCase()} ${this.props.appliedPromoText}`)}
                                    {/* (
                                        <PriceComponent
                                            data={{
                                                price: {
                                                    CustomerContextualPrice: this._calculateDiscount(coupon.Code || '', props.cart)

                                                }
                                            }}
                                            context = {props.context}
                                            id = {props.id}
                                            typeName = {props.typeName}
                                            className = {'msc-promo-code__line-discount-value'}
                                        />) */}
                                    {/* VSI Customization - ends */}
                                </div>
                                <Button
                                    title={props.removePromoText}
                                    className='msc-promo-code__line__btn-remove'
                                    onClick={_removePromotion}
                                    data-value={coupon}
                                    aria-label={`${coupon} ${notApplied ? this.props.notAppliedPromoText : this.props.appliedPromoText},`}
                                    role='button'
                                >
                                    {props.removePromoText}
                                </Button>
                            </div>
                            // VSI Customization - {#52133} - added-aria-label to button End
                        );
                    })}
            </>
        );
    };

    // VSI Customization - START
    private _getBonusCouponsArray = () => {
        if (MsDyn365.isBrowser) {
            const getLocalstorageBonusCoupons = localStorage.getItem('bonusCouponsDetail');
            if (getLocalstorageBonusCoupons) {
                this.bonusCouponsArray = getLocalstorageBonusCoupons && JSON.parse(getLocalstorageBonusCoupons);
            }
        }
    };

    private _isBonusCoupons = (appliedPromoCoupons: string): boolean => {
        let checkBonusCoupons: boolean | undefined = false;
        this.bonusCouponsArray?.map(coupons => {
            checkBonusCoupons = coupons.BonusCoupons?.some(item => item.BonusCoupon?.toUpperCase() === appliedPromoCoupons.toUpperCase());
            return checkBonusCoupons;
        });
        return checkBonusCoupons;
    };
    // VSI Customization - END

    /* VSI Customization start
    This function will loop over the discountLines to filter out the
    exact applied promo codes and return the array to list the
    actual applied promo codes.
    */
    private _getAppliedPromoCodes(cartLines: CartLine[]): string[] {
        this.appliedPromoCodes = [];
        const discountLinePromoCodes: string[] = [];
        // get bonus coupon details set on property bonusCouponsArray
        this._getBonusCouponsArray();
        // get the bonus coupon details and set the data for applied
        // bonus coupons
        const bonusCouponDetails = this._setBonusCouponDetails();
        cartLines.map((cartLine: CartLine) => {
            const discountLines = cartLine.DiscountLines;
            const cartCoupons = this.props.cart?.cart.Coupons;
            discountLines
                ? discountLines.map((discountLine: DiscountLine) => {
                    const codeValue = discountLine.DiscountCode!;
                    codeValue &&
                        codeValue.length > 0 &&
                        discountLinePromoCodes.indexOf(codeValue) === -1 &&
                        discountLinePromoCodes.push(codeValue);

                    const isBonusCoupon = this.allActualBonusCoupons.indexOf(codeValue) > -1;
                    // check parent name of the bonus coupon
                    // end check parent name of the bonus coupon
                    if (isBonusCoupon && codeValue && bonusCouponDetails.length > 0 && bonusCouponDetails[0][codeValue].length > 0) {
                        // only insert in applied if not already
                        if (this.appliedPromoCodes.indexOf(bonusCouponDetails[0][codeValue]) === -1) {
                            this.appliedPromoCodes.push(bonusCouponDetails[0][codeValue]);
                        }
                    } else if (!isBonusCoupon && cartCoupons) {
                        cartCoupons.map(coupon => {
                            const currentCouponCode = coupon.Code;
                            currentCouponCode &&
                                discountLinePromoCodes.indexOf(currentCouponCode) > -1 &&
                                this.appliedPromoCodes.indexOf(currentCouponCode) === -1 &&
                                this.appliedPromoCodes.push(currentCouponCode);
                        });
                    }
                })
                : [];
        });
        return this.appliedPromoCodes;
    }

    /* This function will set the data for all actual bouns coupons which are basically
    childs of actual bonus coupon on allActualBounsCoupons property and will return
    an array of object containing child to parent bonus coupon relationship
    like C-purpleKing (child) : PURPLE (parent)
    */
    private _setBonusCouponDetails = () => {
        const bonusDetails = this.bonusCouponsArray;
        const childToParentBonusCoupons = [];
        this.allActualBonusCoupons = [];
        if (bonusDetails && bonusDetails.length > 0) {
            const newObject = {};
            bonusDetails[0].BonusCoupons &&
                bonusDetails[0].BonusCoupons.map((bonusCoupon: IMFIBonusCouponsDetail) => {
                    const childCoupons = bonusCoupon.ChildCoupons;
                    if (childCoupons && childCoupons.length > 0) {
                        childCoupons.map(childCoupon => {
                            newObject[childCoupon];
                            newObject[childCoupon] = bonusCoupon.BonusCoupon;
                            this.allActualBonusCoupons.push(childCoupon);
                        });
                    }
                });
            Object.keys(newObject).length > 0 && childToParentBonusCoupons.push(newObject);
        }

        return childToParentBonusCoupons;
    };
    /* VSI Customization end */
}

// @ts-ignore
export const PromoCodeComponent: React.FunctionComponent<IPromoCodeProps> = msdyn365Commerce.createComponentOverride<IPromoCodeComponent>(
    'PromoCode',
    { component: PromoCode, ...PromoCodeComponentActions }
);

export default PromoCodeComponent;
