import { IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { mFIGetBonusCouponsDetailsAsync } from '../actions/BonusItemDataActionExtension.g';
import { IMFIBonusItemsDetailsEntity, IMFIBonusItemsResponse, IMFIGetBonusItemsParameters } from '../actions/BonusItemDataServiceEntities.g';
import getCouponInfo, { GetBonusCouponInfoInput } from '../data-actions/get-bonus-coupons-info';
import { MFIbonusitemsInput } from '../data-actions/mfrm-mfi-bonusitem.action';

export const _getBonusCouponsDetails = async (context: ICoreContext<IGeneric<IAny>>, bonusCoupons: string) => {
    if (bonusCoupons === '') {
        try {
            const result = await mFIGetBonusCouponsDetailsAsync({ callerContext: context.actionContext }, bonusCoupons);
            if (result) {
                return result;
            }
        } catch (error) {
            console.log('Error', error);
        }
    }
    return null;
};

// export const _getBonusCouponsDetails;
// Optional paremater mfiBonusItems used to pass state shared mfiBonusItems API response to prevent duplicate calls
export const _getMFIGetBonusItems = async (context: ICoreContext<IGeneric<IAny>>, bonusItemParams: IMFIGetBonusItemsParameters, mfiBonusItems?: IMFIBonusItemsDetailsEntity[] | undefined) => {
    if (bonusItemParams) {
        try {
            let result: IMFIBonusItemsResponse | null;

            // Check if this coupon is new to previously applied promos, then call API
            const isDifferentCouponApplied = mfiBonusItems?.find(mfiBonusItem => {
                return bonusItemParams?.Coupons?.find(bonusItemParam => bonusItemParam !== mfiBonusItem.Coupon);
            });
            if (mfiBonusItems !== undefined && mfiBonusItems.length > 0 && !isDifferentCouponApplied) {
                const mfiBonusItemsResponseObject: IMFIBonusItemsResponse = {
                    BonusItemsDetails: mfiBonusItems
                };
                result = mfiBonusItemsResponseObject;
            } else {
                /* result = await mFIGetBonusItemsDetailAsync({ callerContext: context.actionContext }, bonusItemParams);
                console.log({result}); */

                /// Use data action
                result = await getCouponInfo(
                    new GetBonusCouponInfoInput(bonusItemParams.CartId!, bonusItemParams.Coupons!),
                    context.actionContext
                );
                context.actionContext.update(new MFIbonusitemsInput(), result?.BonusItemsDetails);

            }
            if (result) {
                return result;
            }
        } catch (error) {
            console.log('Error', error);
        }
    }
    return null;
};

// export default _getMFIGetBonusItems;
